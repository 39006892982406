import * as React from "react";
const Close = ( props ) => (
	<svg
		className="sm:w-[1.4rem] sm:h-[1.4rem] lm:w-[1.8rem] lm:h-[1.8rem] tab:w-[2rem] tab:h-[2rem] sl:w-[1.6vw] sl:h-[1.6vw] lt:w-[1.2vw] lt:h-[1.2vw]"
		fill="#04253c"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1024 1024"
		{ ...props }
	>
		<path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
	</svg>
);
export default Close;
